<template>
  <b-card>
    <b-row>
      <b-col>
        <b-row align-h="between">
          <b-col cols="auto">
            <b-row>
              <b-col cols="auto">
                <b-avatar variant="primary" size="40" class="shadow mb-2">
                  <i class="fas fa-coins text-white cursor-pointer icon-2x" />
                </b-avatar>
              </b-col>
              <b-col align-self="center" cols="auto">
                <h1>Bonus Details</h1>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="auto">
            <b-dropdown right text="Filters" variant="primary">
              <b-dropdown-form>
                <b-form-checkbox-group v-model="selectedFilters">
                  <b-form-checkbox value="value_achieved">Value Achieved</b-form-checkbox>
                  <b-form-checkbox value="base_bonus">Base Bonus</b-form-checkbox>
                  <b-form-checkbox value="extra_bonus">Extra Bonus</b-form-checkbox>
                  <b-form-checkbox value="appointments_attended"
                    >Appointments Attended</b-form-checkbox
                  >
                  <b-form-checkbox value="total">Total</b-form-checkbox>
                </b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <!-- Estado de carga -->
      <div v-if="loading" class="text-center w-100">
        <b-spinner variant="primary"></b-spinner>
        <p>Loading bonus data...</p>
      </div>

      <!-- Contenido principal -->
      <template v-else>
        <b-col cols="9">
          <vue-apex-charts
            v-if="typeChart === 1 && series.length > 0"
            :options="chartOptions"
            :series="series"
            height="450"
            type="line" />
        </b-col>
        <b-col cols="3">
          <b-row v-for="({ label, value }, key) in avgs" :key="key">
            <vue-apex-charts
              :options="radialBarOptions(label, value)"
              :series="[value]"
              type="radialBar" />
          </b-row>
        </b-col>
      </template>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BDropdown,
  BDropdownForm,
  BFormCheckboxGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axiosB from '@/core/services/api/admin/bonus'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BDropdown,
    BDropdownForm,
    BFormCheckboxGroup,
    BFormCheckbox,
    VueApexCharts,
  },
  props: {
    date: {
      type: Object,
      required: true,
      validator: value =>
        typeof value.start !== 'undefined' && typeof value.end !== 'undefined',
    },
  },
  data() {
    return {
      loading: false,
      bonusData: [],
      selectedFilters: [
        'value_achieved',
        'base_bonus',
        'extra_bonus',
        'appointments_attended',
        'total',
      ],
      typeChart: 1,
      chartOptions: {
        chart: { id: 'bonus-line-chart', toolbar: { show: true } },
        xaxis: { categories: [] },
        yaxis: { title: { text: 'Value' } },
        stroke: { curve: 'smooth' },
      },
      series: [],
      avgs: [],
      userData: null,
    }
  },
  watch: {
    date: {
      async handler(newDate) {
        if (newDate.start && newDate.end) {
          try {
            this.loading = true
            this.userData = JSON.parse(localStorage.getItem('userData'))

            const response = await axiosB.bonusByUser({
              user_id: this.userData.id,
              rol_id: this.userData.roles[0].id,
              start_date: newDate.start,
              end_date: newDate.end,
            })

            this.bonusData = response.data.bonus
            this.prepareChartData()
            this.prepareRadialBarData()
          } catch (error) {
            console.error('Error fetching bonus details:', error)
            this.bonusData = []
          } finally {
            this.loading = false
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    prepareChartData() {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: this.bonusData.map(bonus => {
            const date = new Date(bonus.day)
            return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })
          }),
        },
      }

      this.series = [
        {
          name: 'Base Bonus',
          data: this.bonusData.map(bonus => this.parseNumber(bonus.base_bonus)),
        },
        {
          name: 'Extra Bonus',
          data: this.bonusData.map(bonus => this.parseNumber(bonus.extra_bonus)),
        },
        {
          name: 'Total',
          data: this.bonusData.map(bonus => this.parseNumber(bonus.total)),
        },
      ]
    },
    prepareRadialBarData() {
      if (!this.bonusData?.length) return

      // Calcular sumatorias de todos los días
      const totals = this.bonusData.reduce(
        (acc, bonus) => ({
          base_bonus: acc.base_bonus + this.parseNumber(bonus.base_bonus),
          extra_bonus: acc.extra_bonus + this.parseNumber(bonus.extra_bonus),
          total: acc.total + this.parseNumber(bonus.total),
        }),
        { base_bonus: 0, extra_bonus: 0, total: 0 }
      )

      this.avgs = [
        { label: 'Total Base Bonus', value: (totals.base_bonus / 500) * 100 },
        { label: 'Total Extra Bonus', value: (totals.extra_bonus / 500) * 100},
        { label: 'Grand Total', value: (totals.total / 500) * 100},
      ]
    },
    parseNumber(value) {
      const number = parseFloat(value)
      return isNaN(number) ? 0 : Number(number.toFixed(2))
    },
    radialBarOptions(label, value) {
      return {
        chart: { type: 'radialBar' },
        plotOptions: {
          radialBar: {
            hollow: { size: '50%' },
            dataLabels: {
              name: { show: true, fontSize: '9px' },
              value: {
                show: true,
                fontSize: '14px',
                formatter: () => `${this.parseNumber((value) * 500 / 100)}`,
              },
            },
          },
        },
        labels: [label],
      };
    },
    percentage(value) {
      return this.parseNumber(value)
    },
  },
}
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}
.text-primary {
  color: #007bff !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-danger {
  color: #dc3545 !important;
}

.loading-wrapper {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
