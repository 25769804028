<template>
  <b-card>
    <h1>Bonus Summary</h1>
    <div style="height: 350px">
      <vue-apex-charts :options="options" :series="series" type="line" />
    </div>

    <div class="mt-3">
      <b-button @click="openModal" variant="primary" class="mr-2">View Details</b-button>
      <b-button variant="success" @click="exportToExcel" :disabled="loadingExcel">
        <span v-if="!loadingExcel">Export to Excel</span>
        <b-spinner v-else small type="grow"></b-spinner>
      </b-button>
    </div>

    <!-- Modal para mostrar detalles -->
    <b-modal v-model="isModalVisible" size="lg" title="Appointments Details" hide-footer>
      <!-- Selector de fecha con búsqueda -->

      <b-form-group label="Select Date">
        <b-form-select
          v-model="selectedDate"
          :options="dateOptions"
          @change="loadAppointments">
          <template #first>
            <b-form-input
              v-model="dateSearchQuery"
              placeholder="Search date..."
              @input="filterDates"></b-form-input>
          </template>
        </b-form-select>
      </b-form-group>

      <!-- Filtros por identificación o nombre -->
      <b-form-group label="Filter by Identification or Name">
        <b-form-input v-model="searchQuery" placeholder="Enter ID or Name"></b-form-input>
      </b-form-group>

      <!-- Tabla de detalles -->
      <b-table
        striped
        hover
        :items="filteredAppointments"
        :fields="tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :empty-text="tableEmptyText">
        <template #cell(user)="data">
          {{ data.item.user.name }} {{ data.item.user.lastname }}
        </template>
        <template #cell(total)="data">
          {{ dollars.format(data.item.total) }}
        </template>
      </b-table>

      <!-- Paginación -->
      <b-pagination
        v-model="currentPage"
        :total-rows="filteredAppointments.length"
        :per-page="perPage"
        aria-controls="appointments-table"></b-pagination>

      <!-- Totales -->
      <div class="mt-3">
        <strong>Grand Total:</strong> {{ dollars.format(grandTotal) }}
      </div>
    </b-modal>

    <!-- Loading overlay para Excel -->
    <div v-if="loadingExcel" class="excel-loading-overlay">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem"></b-spinner>
      <div class="mt-2">Generating Excel file...</div>
    </div>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import * as XLSX from 'xlsx'
import {
  BCard,
  BModal,
  BTable,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BModal,
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  props: {
    bonus: {
      type: Object,
      default: () => ({}),
      required: true, // Asegúrate de que el prop sea requerido
    },
  },
  data() {
    return {
      loadingExcel: false,
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true,
          },
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
          },
        },
        stroke: {
          curve: 'smooth',
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            rotate: -45,
          },
        },
        yaxis: {
          title: {
            text: 'Amount',
          },
        },
        colors: ['#008FFB', '#00E396'],
      },
      isModalVisible: false,
      selectedDate: null,
      dateSearchQuery: '',
      searchQuery: '',
      perPage: 10,
      currentPage: 1,
      tableFields: [
        { key: 'user', label: 'User' },
        { key: 'value_appointment', label: 'Appointment Value' },
        { key: 'value_achieved', label: 'Value Achieved' },
        { key: 'total', label: 'Total' },
      ],
      dollars: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }),
    }
  },
  computed: {
    dateOptions() {
      const dates = new Set()
      Object.values(this.bonus).forEach(location => {
        Object.keys(location).forEach(date => dates.add(date))
      })
      return Array.from(dates)
        .filter(date => date.includes(this.dateSearchQuery))
        .sort()
    },
    filteredAppointments() {
      if (!this.selectedDate) return []
      let appointments = []
      Object.values(this.bonus).forEach(location => {
        if (location[this.selectedDate]) {
          appointments = appointments.concat(location[this.selectedDate].appointments)
        }
      })
      return appointments.filter(appointment => {
        const user = appointment.user
        return (
          user.identification?.includes(this.searchQuery) ||
          user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.lastname.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })
    },
    grandTotal() {
      return this.filteredAppointments.reduce((sum, item) => sum + (item.total || 0), 0)
    },
  },
  methods: {
    async exportToExcel() {
      try {
        this.loadingExcel = true
        const workbook = XLSX.utils.book_new()
        const dateMap = new Map()

        // Mapear datos
        Object.entries(this.bonus).forEach(([locationName, dates]) => {
          Object.entries(dates).forEach(([date, dateData]) => {
            if (!dateMap.has(date)) {
              dateMap.set(date, {
                total_to_pay: 0,
                appointments: [],
              })
            }
            const current = dateMap.get(date)
            current.total_to_pay += dateData.total || 0
            current.appointments.push(
              ...dateData.appointments.map(appt => ({
                ...appt,
                location: locationName,
              }))
            )
          })
        })

        // Hoja de resumen
        const summaryData = Array.from(dateMap.entries()).map(([date, data]) => ({
          Date: date,
          'Total Appointments': data.appointments.length,
          'Total Bonus Paid': data.total_to_pay,
          'Average per Appointment':
            data.appointments.length > 0
              ? data.total_to_pay / data.appointments.length
              : 0,
        }))

        XLSX.utils.book_append_sheet(
          workbook,
          XLSX.utils.json_to_sheet(summaryData),
          'Summary'
        )

        // Hojas detalladas
        dateMap.forEach((data, date) => {
          const worksheetData = data.appointments.map(appointment => ({
            Date: date,
            'Facility Name': appointment.location,
            'User Name': `${appointment.user.name} ${appointment.user.lastname}`,
            'Appointment Value': appointment.value_appointment,
            'Value Achieved': appointment.value_achieved,
            'Base Bonus': appointment.base_bonus,
            'Extra Bonus': appointment.extra_bonus,
            'Total Bonus': appointment.total,
            'User Type': appointment.user_type,
          }))
          XLSX.utils.book_append_sheet(
            workbook,
            XLSX.utils.json_to_sheet(worksheetData),
            date.substring(0, 10)
          )
        })

        // Generar archivo
        const buffer = XLSX.write(workbook, { type: 'array' })
        this.downloadExcel(buffer, 'bonus_report.xlsx')
      } catch (error) {
        console.error('Error generating Excel:', error)
      } finally {
        this.loadingExcel = false
      }
    },

    downloadExcel(buffer, fileName) {
      const blob = new Blob([buffer], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    },

    openModal() {
      this.isModalVisible = true
    },
    loadAppointments() {
      this.currentPage = 1 // Reiniciar la paginación
    },
    filterDates() {
      this.selectedDate = null // Reiniciar la fecha seleccionada al buscar
    },
    setData(bonusData) {
      const dateMap = new Map()

      // Iterar sobre cada ubicación
      Object.values(bonusData).forEach(location => {
        // Iterar sobre cada fecha en la ubicación
        Object.entries(location).forEach(([date, dateData]) => {
          if (!dateMap.has(date)) {
            dateMap.set(date, {
              total_to_pay: 0,
              total_appointments: dateData.appointments?.length || 0,
            })
          }
          const current = dateMap.get(date)
          current.total_to_pay += dateData.total || 0
          current.total_appointments += dateData.appointments?.length || 0
        })
      })

      const dates = Array.from(dateMap.keys()).sort()

      this.series = [
        {
          name: 'Total Bonus',
          data: dates.map(date => dateMap.get(date).total_to_pay),
        },
        {
          name: 'Total Appointments',
          data: dates.map(date => dateMap.get(date).total_appointments),
        },
      ]

      this.options = {
        ...this.options,
        xaxis: {
          ...this.options.xaxis,
          categories: dates,
        },
      }
    },
  },
  watch: {
    // Reactividad cuando cambia el prop "bonus"
    bonus: {
      handler(newVal) {
        this.setData(newVal)
      },
      immediate: true,
    },
  },
  mounted() {
    this.setData(this.bonus) // Inicializar los datos al montar el componente
  },
}
</script>

<style scoped>
.apexcharts-tooltip {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.excel-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
</style>
