<template>
  <section>
    <div class="grid-container">
      <div class="grid-item">
        <welcome :userData="userData" />
        <filtro :isLoading="isLoading" class="mt-3" @buscar="buscar($event)" />
        <statisticsPatients
          class="mt-3"
          :userData="userData"
          :dataDashboard="dataDashboard" />
      </div>
      <div class="grid-item">
        <bonus :date="this.fechas"/>
        <statistics :stadistics="stadistics" />
        <totalPatiens
          class="mt-3"
          :userData="userData"
          :dataDashboard="dataDashboard"
          :fechas="fechas" />
        <membership class="mt-3" :userData="userData" :memberships="sumMembership" />
      </div>
    </div>
  </section>
</template>

<script>
import Welcome from '../components/welcome'
import filtro from '../components/filter'
import statisticsPatients from '../components/statisticsPatients'
import totalPatiens from '../components/totalPatiens'
import statistics from '../components/statistics'
import membership from '../components/membership'
import bonus from '../components/bonus'

export default {
  components: {
    Welcome,
    filtro,
    statisticsPatients,
    statistics,
    bonus,
    totalPatiens,
    membership,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
      required: false,
    },
    dataDashboard: {
      type: Object,
      default: () => {},
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      stadistics: [],
      fechas: {
        start: '',
        end: '',
      },
      sumMembership: { NP: 0, NC: 0 },
    }
  },
  watch: {
    dataDashboard() {
      this.stadistics = [
        {
          description: 'Average cash',
          value: parseInt(this.dataDashboard.average.cash_days),
          title: 'AVG Cash',
        },
        {
          description: 'Average CC',
          value: parseInt(this.dataDashboard.average.cc_days),
          title: 'AVG CC',
        },
        {
          description:
            'Es la facturación diaria de la clínica, que va mostrando los cambios en tiempo real con cada cobro. Una vez finalizado el turno este indicador se reinicia en 0 y esa facturación total pasa al Daily Income AVG.',
          value: parseInt(this.dataDashboard.average.income_day),
          title: 'Daily income',
        },
        {
          description:
            'es el average diario de facturacion de la clinica, que se obtiene sumando la facturacion de todos los dias transcurridos del mes entre el numero de dias transcurridos, cambia una sola vez al dia, de un dia para otro en la madrugada',
          value: parseInt(this.dataDashboard.average.income_days),
          title: 'AVG Days income',
        },
      ]
      this.sumMembership = this.dataDashboard.memberships
    },
  },
  methods: {
    buscar(filtro) {
      this.fechas = {
        start: filtro.start,
        end: filtro.end,
      }
      this.$emit('buscar', filtro)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/cssDashboards.css';
</style>
